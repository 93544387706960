.footer {
    background-color: #f8f8f8;
    margin: 0 30px;
    border-radius: 60px 60px 0 0;
    box-shadow: 0px 5px 20px 0px rgb(32 32 32 / 10%);
    padding: 30px 30px 20px;
}

.footerCol {
    padding: 0;
    line-height: 23px;
    font-size: 24px;
}

.copyright {
    text-align: center;
    font-size: 20px;
    margin: 0;
}

.footer hr {
    margin: 0.5rem 0 1rem;
}

.footer span {
    white-space: nowrap;
}

.imgLink {
    margin-left: 20px;
}

.gray {
    color: #787878;
}

.bold {
    text-transform: uppercase;
    font-weight: 700;
}

.bot {
    display: none;
}

@media screen and (max-width: 480px) {
    .footer {
        margin: 0;
    }
}