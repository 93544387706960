.header {
    background-color: #f8f8f8;
    margin: 0 30px;
    border-radius: 0 0 60px 60px;
    box-shadow: 0px 5px 20px 0px rgb(32 32 32 / 10%);
    padding: 30px 40px;
    text-transform: uppercase;
    flex: 0 0 auto;
}

.headerCol {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header a {
    text-decoration: none;
    color: #000;
    margin-right: 20px;
}

.headerCol a:last-child {
    margin-right: 0;
}

.header a:hover {
    color: #000;
}

.header div a:nth-child(2) {
    font-weight: 700;
    display: block;
}

.verticalHr {
    height: 55px;
    width: 2px;
    background-color: rgba(0, 0, 0, 0.3);
}

.molodeznaya a:nth-child(2) {
    font-size: 10px;
    max-width: 250px;
}

.buttonToTickets {
    display: flex;
    flex-direction: column;
}

.buttonToTickets a {
    margin: 0;
}

.buttonToTickets button {
    width: 100%;
}

.buttonToTickets p {
    font-size: 10px;
    margin: 0;
    font-weight: 700;
    color: #5e7fd6;
    text-transform: none;
}

@media screen and (max-width: 1400px) {
    .headerCol {
        flex-direction: column;
    }

    .header a {
        margin: 0;
    }
}

@media screen and (max-width: 1199px) {
    .header {
        padding: 20px 0;
        justify-content: space-around !important;
    }

    .school a:first-child,
    .hochuVNauku a:first-child,
    .molodeznaya a:first-child {
        height: 45px;
    }

    .verticalHr.first {
        display: none;
    }

    .verticalHr.second {
        order: 1;
        width: 100%;
        height: 2px;
        margin: 20px 0;
    }

    .buttonToTickets {
        order: 2;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .headerCol {
        justify-content: normal;
    }

    .school a:last-child,
    .hochuVNauku a:last-child {
        font-size: 10px;
    }

    .molodeznaya a:last-child {
        font-size: 8px;
        text-align: center;
        max-width: 200px;
    }
}

@media screen and (max-width: 480px) {
    .header {
        margin: 0;
    }

    .header img {
        width: 45px;
        max-height: 45px;
        object-fit: contain;
    }

    .school a:last-child,
    .hochuVNauku a:last-child {
        font-size: 8px;
    }

    .molodeznaya a:last-child {
        font-size: 6px;
        max-width: 150px;
    }

    .buttonToTickets button {
        font-size: 20px;
    }

    .buttonToTickets p {
        font-size: 8px;
    }
}