.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-btn-toolbar {
  display: inline !important;
}

.custom-btn-toolbar > .btn {
  margin-right: 1rem;
}

.btn.active {
  background: none;
  color: #000;
  border: transparent solid 2px;
}



.header {
  background-color: #f8f8f8;
  margin: 0 30px;
  border-radius: 0 0 60px 60px;
  box-shadow: 0px 5px 20px 0px rgb(32 32 32 / 10%);
  padding: 30px 40px;
  text-transform: uppercase;
  flex: 0 0 auto;
}

.header .col {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header a {
  text-decoration: none;
  color: #000;
  margin-right: 20px;
}

.header .col a:last-child {
  margin-right: 0;
}

.button-to-tickets {
  display: flex;
  flex-direction: column;
}

.button-to-tickets a {
  margin: 0;
}

.button-to-tickets button {
  width: 100%;
}

.button-to-tickets p {
  font-size: 10px;
  margin: 0;
  font-weight: 700;
  color: #5e7fd6;
  text-transform: none;
}

.vertical-hr {
  height: 55px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.3);
}

.header a:hover {
  color: #000;
}

.header div a:nth-child(2) {
  font-weight: 700;
  display: block;
}

.molodeznaya a:nth-child(2) {
  font-size: 10px;
  max-width: 250px;
}

.banner {
  padding: 60px;
  justify-content: space-between;
  gap: 20px;
}

.banner .col {
  flex: 0 0 auto;
  width: auto;
}

.banner img {
  height: 100%;
  object-fit: contain;
  border-radius: 122px !important;
}

.banner h1 {
  font-size: calc(2.3rem + 1.5vw);
  font-weight: 700;
  margin-bottom: 10px;
}

.banner p {
  font-size: 25px;
  margin-bottom: 80px;
}

.banner p:first-of-type {
  font-size: 15px;
  text-transform: uppercase;
  color: #00000080;
  margin-bottom: 50px;
}

.banner a {
  padding: 18px 60px;
}

a.hidden {
  display: none;
}

.about.row {
  padding: 60px;
  gap: 60px;
}

.about span {
  font-size: 20px;
}

.about > .col {
  align-items: center;
  padding: 0;
}

.about__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 40px;
  background-color: #5e7fd6;
  min-width: 269px;
  height: 250px;
  color: #fff;
  box-shadow: 6px 6px 15px 0 #2b2ac5;
}

.about__item h2 {
  font-size: 3rem;
  margin-bottom: 21px;
}

.present {
  padding: 60px;
  gap: 150px;
}

.present img {
  width: 440px;
  object-fit: contain;
}

.present .col {
  flex: 0 0 auto;
  width: auto;
}

.present h2 {
  font-weight: 700;
  margin-bottom: 50px;
  font-size: 3rem;
}

.present ul {
  padding-inline-start: 0;
  list-style-type: none;
}

.present li {
  font-size: 24px;
  max-width: 600px;
  margin-bottom: 20px;
  line-height: 30px !important;
  position: relative;
}

.present li::before {
  display: flex;
  content: url("shared/image/dot.png");
  position: absolute;
  height: 100%;
  align-items: center;
  left: -30px;
}

.map {
  padding: 60px;
  text-align: center;
}

.map h2 {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 3rem;
  width: 100%;
  text-align: center;
}

.map h3 {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 1.5rem;
  width: auto;
  text-align: left;
  margin-left: 70px;
  min-width: 305px;
}

.map__steps span {
  font-size: 36px;
  font-weight: 700;
  margin-left: -70px;
}

.map__steps p {
  min-width: 305px;
  font-size: 20px;
  margin-bottom: 0;
  text-align: left;
  margin-left: 70px;
}

.map__steps img {
  object-fit: contain;
  width: 200px;
  height: 200px;
  margin: 25px 15px;
}

.archive h2,
.partners h2 {
  font-size: 3rem;
  font-weight: 700;
  width: auto;
  margin-bottom: 30px;
  z-index: 3;
}

.partners .title {
  flex: 0 0 auto;
  justify-content: center;
  display: flex;
}

.archive {
  position: relative;
  margin: 0 0 60px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.archive::before,
.archive::after {
  background: linear-gradient(to right, #fff, #fff0);
  content: "";
  height: 100%;
  position: absolute;
  width: 10px;
  z-index: 3;
}

.archive::before {
  left: 0;
}

.archive::after {
  right: 0;
  transform: rotateZ(180deg);
}

.partners {
  padding: 20px 0 60px;
}

.partners img {
  width: 160px;
  height: 160px;
  margin-right: 50px;
  object-fit: contain;
  padding: 0;
}

.registration {
  padding: 0 60px 60px;
}

.registration .col {
  max-width: 900px;
  display: flex;
  justify-content: center;
}

.registration a {
  padding: 18px 133px;
  width: 100%;
}

.registration-form .text-muted {
  font-size: 25px;
}

button,
.banner a.btn,
.registration a.btn {
  font-weight: 700 !important;
}

.footer {
  background-color: #f8f8f8;
  margin: 0 30px;
  border-radius: 60px 60px 0 0;
  box-shadow: 0px 5px 20px 0px rgb(32 32 32 / 10%);
  padding: 30px 30px 20px;
}

.footer .col {
  padding: 0;
  width: auto;
  line-height: 23px;
}

.footer div > * {
  font-size: 24px;
}

.footer p.copyright {
  text-align: center;
  font-size: 20px;
  margin: 0;
}

.footer hr {
  margin: 0.5rem 0 1rem;
}

.footer span {
  white-space: nowrap;
}

.footer .img-link {
  margin-left: 20px;
}

.footer .gray {
  color: #787878;
}

.footer .bold {
  text-transform: uppercase;
  font-weight: 700;
}

.footer .tel {
  text-decoration: none;
  color: #000;
}

.footer .bot {
  display: none;
}

.add-child-button {
  height: 50px;
  width: 50px;
  padding: 0;
  margin-bottom: 50px !important;
}

.add-child-button img {
  margin: auto;
  height: 30px;
}

.dates-row > .col {
  flex: 0 0 auto;
  width: auto;
}

.dates-row {
  margin: 0 20px 60px;
  display: flex;
  justify-content: flex-start;
  gap: 15px 20px;
}

.slot-times {
  max-width: 440px;
  min-width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.slots {
  display: flex;
}

.slots span {
  margin-bottom: 10px;
}

a.date-button {
  font-size: 24px !important;
  font-weight: 400 !important;
  border: transparent solid 2px;
  line-height: 30px;
  padding: 10px;
  width: 150px;
}

a.date-button:hover {
  border: #5e7fd6 solid 2px !important;
  background-color: none !important;
  color: #000;
}

a.date-button:active {
  border: transparent solid 2px !important;
  background-color: transparent !important;
  color: #5e7fd6 !important;
}

a.date-button_checked {
  background-color: #5e7fd6 !important;
  color: #fff !important;
}

a.date-button_checked:hover {
  background-color: #5e7fd6;
  color: #fff;
}

a.date-button_checked:active {
  background-color: #5e7fd6 !important;
  color: #fff !important;
}

.day-title {
  font-size: 70px;
  font-weight: 700;
  margin: 0 0 30px 30px;
}

.day-title_h2 {
  font-size: 48px;
  margin: 0 0 30px 30px;
}

.time-row {
  margin: 0 0 60px 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 15px 30px;
}

.time-row .col {
  width: auto;
  flex: 0 0 auto;
}

.time-button {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px;
  border: #b8b8b8 solid 2px !important;
  width: 96px;
}

.time-button:hover {
  background-color: transparent !important;
  color: #000 !important;
  border: #868686 solid 2px !important;
}

.time-button.time-button_checked:hover {
  border: #5e7fd6 solid 2px !important;
}

.time-button:active {
  border: #5e7fd6 solid 2px !important;
}

.time-button_checked {
  border: #5e7fd6 solid 2px !important;
}

.time-button.time-button_disabled {
  cursor: default !important;
  border: #cccccc solid 2px !important;
  background-color: #eeeeee !important;
  color: #cccccc !important;
}

.time-button.time-button_disabled:hover {
  cursor: default !important;
  border: #cccccc solid 2px !important;
  background-color: #eeeeee !important;
  color: #cccccc !important;
}

.events-row {
  padding: 50px;
  background-color: #f8f8f8;
  border-radius: 60px;
  gap: 70px;
  margin-bottom: 60px;
}

.event-card {
  position: relative;
  display: flex;
  margin-left: 70px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 60px;
  min-height: 455px;
  padding: 12px 30px 30px;
  box-shadow: 7px 10px 7px 0 rgba(0, 0, 0, 0.25);
}

.event-card > div {
  flex: 1 0 auto;
}

.event-card .img-wrapper {
  position: absolute;
  display: flex;
  height: 100px;
  width: 100px;
  top: -17px;
  left: -62px;
  background-color: #fff;
  padding: 12px;
  border-radius: 50%;
  border: rgba(164, 164, 164, 0.5) solid 2px;
  overflow: hidden;
}

.event-card img {
  object-fit: contain;
}

.event-card h3 {
  margin: 0 0 0 27px;
  font-weight: 700;
  font-size: 24px;
  min-height: 60px;
}

.event-card hr {
  margin: 0 0 5px 0;
  height: 3px;
}

.event-card span,
.event-card p {
  font-size: 18px;
  font-weight: 500;
}

span.event-card__title {
  color: #787878;
  font-size: 16px;
  font-weight: 400;
}

.event-card__time.time-button {
  position: relative;
  cursor: pointer;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 15px;
  margin-bottom: 30px;
  text-align: center;
  width: 65px;
}

.event-card__button {
  width: 240px;
  font-size: 16px !important;
  align-self: center;
  color: #5e7fd6 !important;
  border: #5e7fd6 solid 2px !important;
}

.event-card__button:hover {
  color: #fff !important;
  background-color: #5e7fd6 !important;
}

.event-card__button:active {
  background-color: #5777cf !important;
  border: #5777cf solid 2px !important;
}

.registration-form input {
  height: 50px;
  margin-bottom: 20px;
}

.form-control {
  border: 3px solid #ced4da;
}

.form-control:focus {
  border-color: #5e7fd6;
  box-shadow: 0 0 0 0.25rem #5e7fd640;
}

.form-check-input:checked {
  background-color: #5e7fd6;
  border-color: #5e7fd6;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem #5e7fd640;
}

.registration-form p,
.registration-form label {
  font-size: 30px;
}

.registration-form .form-check {
  line-height: 30px;
}

.registration-form .form-check-input {
  height: 30px;
  width: 30px;
  margin-top: 0;
  margin-right: 15px;
}

.child-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.registration-info {
  justify-content: space-between;
  margin: 0 30px 0;
}

.registration-info__event {
  margin: 0 0 0 30px;
  background-color: #f8f8f8;
  height: fit-content;
  padding: 30px;
  border-radius: 30px;
  max-width: 550px;
}

.registration-info__event h1 {
  font-weight: 700;
  font-size: 24px;
}

.registration-info__event p {
  font-size: 18px;
  font-weight: 500;
}

.registration-info__event p span {
  font-size: 16px;
  color: #787878;
}

.registration-info__event p:last-child {
  font-weight: 400;
  margin-bottom: 0;
  background-color: #ffffff80;
  padding: 20px;
  border-radius: 15px;
}

.registration-page__button {
  margin-bottom: 20px;
}

.rules {
  display: flex;
  width: 100%;
  margin: 60px 0 30px;
}

.rules a {
  width: 50px;
  height: 100%;
  margin-right: 35px;
  display: flex;
}

.rules a img {
  object-fit: contain;
}

.warning {
  display: flex;
  width: 100%;
  padding: 20px 23px;
  align-items: center;
  gap: 16px;
  background-color: #f8f8f8;
  margin-right: 30px;
}

.warning img {
  width: 35px;
  height: 35px;
}

.warning p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.warning p span {
  color: #5e7fd6;
  font-weight: 700;
}

.registration-form .warning {
  margin-bottom: 40px;
}

.policy {
  margin-bottom: 60px;
}

.ticket__title {
  font-size: 70px;
  font-weight: 700;
  margin-bottom: 60px;
  text-align: center;
}

.ticket-wrapper {
  background: url("shared/image/ticket.png");
  margin-bottom: 50px;
  padding: 50px 60px;
  border-radius: 35px;
  background-size: 100%;
}

.ticket-wrapper div {
  border-radius: 20px;
  background: #fff;
}

.ticket p {
  margin: 0;
}

.ticket__number {
  padding: 10px 30px;
  font-weight: 700;
  font-size: 60px;
  border-bottom: #a4a4a4 solid 2px;
}

.ticket__event {
  font-size: 25px;
  font-weight: 700;
  padding: 25px 30px 15px;
}

.ticket span {
  color: #787878;
  font-size: 16px;
}

.ticket__date,
.ticket__time {
  font-weight: 500;
  font-size: 18px;
  padding: 0px 30px 10px;
}

.ticket__time span:last-child {
  font-size: 18px;
  border: #5e7fd6 solid 2px;
  padding: 5px 10px;
}

.ticket__location {
  padding: 0 30px 25px;
}

.ticket__seats {
  font-weight: 700;
  font-size: 20px;
  padding: 0 30px 25px;
}

.ticket__info {
  padding: 25px 30px 30px;
  border-top: #a4a4a4 solid 2px;
  font-size: 20px;
}

.ticket .warning {
  margin-top: 50px;
}

.warning_event {
  margin: 40px 20px;
  width: auto;
}

.event-top-info {
  display: flex;
  justify-content: flex-start;
  margin: 30px 0;
}

.event-top-info .col {
  flex: 0 0 auto;
  width: auto;
}

.event-top-info .warning_event {
  margin: 0 !important;
  width: 100%;
}

.event-top-info .col:first-child {
  align-self: center;
  margin-right: 50px;
}

.event-top-info .col:last-child {
  gap: 10px;
  display: flex;
  flex: 1 0 auto;
}

.event-top-info img {
  object-fit: contain;
}

.event-top-info .info-img {
  position: relative;
}

.event-top-info .info-img::after {
  content: attr(alt);
  position: absolute;
  bottom: -25px;
  font-weight: 700;
  left: -31px;
  margin: auto;
}

.event-top-info .info-img:first-child::after {
  left: -11px;
}

.event-top-info .info-img:last-child::after {
  left: -2px;
}

.registration-info__button {
  margin: 0 35px;
}

.event-card__title-date {
  margin-left: 30px;
}

.partners .marquee-container {
  overflow-x: scroll !important;
}

.partners .marquee-container::-webkit-scrollbar {
  display: none;
}

.partners .marquee-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.partners .col:last-child {
  position: relative;
}

.partners .col:last-child::before,
.partners .col:last-child::after {
  background: linear-gradient(to right, #fff, #fff0);
  content: "";
  height: 100%;
  position: absolute;
  width: 20px;
  z-index: 3;
}

.partners .col:last-child::before {
  left: -1px;
}

.partners .col:last-child::after {
  right: -1px;
  top: 0;
  transform: rotateZ(180deg);
}

.tickets-page {
  max-width: min-content;
  padding: 0 0 30px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.tickets-page .ticket-wrapper p {
  margin: 0;
}

.tickets-page .ticket__location {
  padding-bottom: 10px;
}

.tickets-page .ticket__seats {
  padding-bottom: 25px;
}

.tickets-page_with-tickets .tickets-page__form {
  display: flex;
  width: 100%;
  max-width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.tickets-page_with-tickets input {
  width: 300px;
}

.tickets-page_with-tickets button {
  margin: 24px 0 12px;
  font-size: 20px;
  white-space: nowrap;
}

.tickets-page__form {
  max-width: 320px;
}

.tickets-page__form input {
  height: 50px;
  margin-bottom: 20px;
}

.tickets-page .ticket-wrapper {
  background: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.tickets-page .ticket-wrapper div {
  border: #f8f8f8 solid 2px;
  box-shadow: 0px 5px 20px 0px rgb(32 32 32 / 10%);
}

.tickets-page_with-tickets .ticket__number {
  font-size: 2rem;
}

.tickets-page_with-tickets .ticket__info {
  margin: 0;
}

.event-card__description {
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.event-card__location {
  margin-bottom: 20px;
  font-weight: 500;
}

.event-card__age span,
.event-card__duration span,
.event-card__location span {
  font-size: 16px;
}

.time-button_checked::after {
  padding: 0 5px;
  content: attr(data-seats);
  position: absolute;
  align-items: center;
  display: flex;
  bottom: -25px;
  left: -15px;
  text-align: center;
  background: #5e7fd6;
  border-radius: 10px;
  font-size: 10px;
  color: #fff;
  width: max-content;
  height: 20px;
}

.event-card.present-15 {
  min-height: fit-content;
}

.present-15 .event-card__title-date {
  margin-left: 18px;
}

.present-15 .event-card__description {
  -webkit-line-clamp: unset;
}

.present-15 .event-card__button {
  color: #787878 !important;
  border-color: #787878 !important;
}

.present-15 .img-wrapper {
  align-items: center;
}

/* carousel */

input[type="radio"] {
  display: none;
}

.carousel .card {
  position: absolute;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
  opacity: 0;
}

.carousel.container {
  width: 100%;
  max-width: 30%;
  height: 450px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .cards {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

#item-1:checked ~ .cards #photo-10,
#item-2:checked ~ .cards #photo-1,
#item-3:checked ~ .cards #photo-2,
#item-4:checked ~ .cards #photo-3,
#item-5:checked ~ .cards #photo-4,
#item-6:checked ~ .cards #photo-5,
#item-7:checked ~ .cards #photo-6,
#item-8:checked ~ .cards #photo-7,
#item-9:checked ~ .cards #photo-8,
#item-10:checked ~ .cards #photo-9 {
  opacity: 1;
  transform: translatex(-90%) scale(0.75);
  filter: blur(3px);
  z-index: 1;
}

#item-1:checked ~ .cards #photo-2,
#item-2:checked ~ .cards #photo-3,
#item-3:checked ~ .cards #photo-4,
#item-4:checked ~ .cards #photo-5,
#item-5:checked ~ .cards #photo-6,
#item-6:checked ~ .cards #photo-7,
#item-7:checked ~ .cards #photo-8,
#item-8:checked ~ .cards #photo-9,
#item-9:checked ~ .cards #photo-10,
#item-10:checked ~ .cards #photo-1 {
  opacity: 1;
  transform: translatex(90%) scale(0.75);
  filter: blur(3px);
  z-index: 1;
}

#item-1:checked ~ .cards #photo-3,
#item-2:checked ~ .cards #photo-4,
#item-3:checked ~ .cards #photo-5,
#item-4:checked ~ .cards #photo-6,
#item-5:checked ~ .cards #photo-7,
#item-6:checked ~ .cards #photo-8,
#item-7:checked ~ .cards #photo-9,
#item-8:checked ~ .cards #photo-10,
#item-9:checked ~ .cards #photo-1,
#item-10:checked ~ .cards #photo-2 {
  opacity: 1;
  transform: translatex(156%) scale(0.5);
  z-index: 0;
  filter: blur(6px);
}

#item-1:checked ~ .cards #photo-9,
#item-2:checked ~ .cards #photo-10,
#item-3:checked ~ .cards #photo-1,
#item-4:checked ~ .cards #photo-2,
#item-5:checked ~ .cards #photo-3,
#item-6:checked ~ .cards #photo-4,
#item-7:checked ~ .cards #photo-5,
#item-8:checked ~ .cards #photo-6,
#item-9:checked ~ .cards #photo-7,
#item-10:checked ~ .cards #photo-8 {
  opacity: 1;
  transform: translatex(-156%) scale(0.5);
  z-index: 0;
  filter: blur(6px);
}

#item-1:checked ~ .cards #photo-1,
#item-2:checked ~ .cards #photo-2,
#item-3:checked ~ .cards #photo-3,
#item-4:checked ~ .cards #photo-4,
#item-5:checked ~ .cards #photo-5,
#item-6:checked ~ .cards #photo-6,
#item-7:checked ~ .cards #photo-7,
#item-8:checked ~ .cards #photo-8,
#item-9:checked ~ .cards #photo-9,
#item-10:checked ~ .cards #photo-10 {
  opacity: 1;
  transform: translatex(0) scale(1);
  z-index: 2;
  filter: none;
}

/* loader */

.loader {
  flex: 1 1 auto;
  display: flex;
}

.solar-system {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 80;
  margin: auto;
}

#mercury {
  animation: mercury 1s infinite linear;
}

#venus {
  animation: venus 2.56s infinite linear;
}

#earth {
  animation: earth 4.15s infinite linear;
}

#mars {
  animation: mars 7.805s infinite linear;
}

@keyframes mercury {
  from {
    transform: rotate(0deg) translateX(-35px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-35px) rotate(-360deg);
  }
}

@keyframes venus {
  from {
    transform: rotate(0deg) translateX(-55px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-55px) rotate(-360deg);
  }
}

@keyframes earth {
  from {
    transform: rotate(0deg) translateX(-75px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-75px) rotate(-360deg);
  }
}

@keyframes mars {
  from {
    transform: rotate(0deg) translateX(-95px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(-95px) rotate(-360deg);
  }
}

@media screen and (max-width: 1400px) {
  .header .col {
    flex-direction: column;
  }

  .header a {
    margin: 0;
  }
}

@media screen and (max-width: 1199px) {
  .banner h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  .map h3,
  .map p {
    margin-left: 0;
  }

  .header {
    padding: 20px 0;
    justify-content: space-around !important;
  }

  .school a:first-child,
  .hochu-v-nauku a:first-child,
  .molodeznaya a:first-child {
    height: 45px;
  }

  .vertical-hr.first {
    display: none;
  }

  .vertical-hr.second {
    order: 1;
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }

  .button-to-tickets {
    order: 2;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 100% !important;
  }

  .carousel.container {
    max-width: 60% !important;
  }

  .banner a {
    display: none;
  }

  .banner p {
    margin-bottom: 20px;
  }

  .registration a,
  .banner a.hidden {
    padding: 18px 0.75rem;
    width: 100%;
  }

  .registration-info__event {
    margin: 0 0 30px 0;
    min-width: 100%;
    order: 1;
  }

  .registration-form {
    order: 2;
  }

  .banner a.hidden {
    display: block;
    width: 100%;
  }

  .banner .col {
    width: 100%;
  }

  .map__steps h3 {
    text-align: center;
    margin-left: 0;
  }

  .map__steps p {
    max-width: 100%;
  }

  .tickets-page_with-tickets .tickets-page__form {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .header .col {
    justify-content: normal;
  }

  .slot-times {
    min-width: 100px;
  }

  .events-row {
    padding: 50px 0;
  }

  .day-title {
    font-size: 3rem;
  }

  .event-card {
    border: #f8f8f8 solid 2px;
  }

  .event-card h3 {
    font-size: 20px;
  }

  .events-row {
    background: none;
  }

  .warning {
    border-radius: 20px !important;
  }

  .tickets-page_with-tickets .tickets-page__form button {
    margin: 0 0 20px;
    width: 300px;
  }

  .school a:last-child,
  .hochu-v-nauku a:last-child {
    font-size: 10px;
  }

  .molodeznaya a:last-child {
    font-size: 8px;
    text-align: center;
    max-width: 200px;
  }
}

@media screen and (max-width: 480px) {
  .carousel.container {
    max-width: 60% !important;
    max-height: 300px;
  }

  .map {
    padding: 30px 0;
  }

  .map p,
  .map h3 {
    text-align: left;
  }

  .map h2,
  .archive h2,
  .partners h2,
  .present h2 {
    font-size: 2rem;
  }

  .banner,
  .present {
    padding: 60px 10px 0;
  }

  .header,
  .footer {
    margin: 0;
  }

  .map__steps img {
    margin: 25px -25px 15px 25px;
  }

  .present {
    gap: 50px;
  }

  .registration-info {
    margin: 0 10px;
  }

  .registration {
    padding: 0 10px 60px;
  }

  .registration-info__button {
    margin: 0 10px;
  }

  label.form-check-label {
    font-size: 25px;
  }

  .event-top-info .info-img {
    width: 55px;
  }

  .event-top-info .col:first-child {
    margin-right: 0;
  }

  .event-top-info .col:last-child {
    justify-content: center;
    width: 100%;
  }

  .day-title {
    font-size: 2rem;
  }

  .event-card {
    margin-left: 0;
  }

  .event-card h3 {
    font-size: 17px;
    margin: 0 0 0 78px;
  }

  .event-card .img-wrapper {
    top: -38px;
    left: 4px;
  }
  
  .header img {
    width: 45px;
    max-height: 45px;
    object-fit: contain;
  }

  .school a:last-child,
  .hochu-v-nauku a:last-child {
    font-size: 8px;
  }

  .molodeznaya a:last-child {
    font-size: 6px;
    max-width: 150px;
  }

  .button-to-tickets button {
    font-size: 20px;
  }

  .button-to-tickets p {
    font-size: 8px;
  }
}

.add-event-form input {
  width: 100%;
}

.add-event-form .form-check-input {
  width: 30px;
  height: 30px;
}
