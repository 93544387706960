.error {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: 100vw ;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    margin: 0 ;
  }
  
  .error img {
    width: 100%;
    max-width: 550px;
    object-fit: contain;
  }
  
  .error h1 {
    font-size: 5rem;
    font-weight: 700;
    color: #5e7fd6;
  }
  
  .error p {
    font-size: 3rem;
    color: #5e7fd6;
  }

  @media (min-width: 960px) {
    .error p {
        font-size: 2rem;
        text-align: center;
      }
    
      .error .btn {
        font-size: 18px;
      }
  }