.hr {
    height: 3px;
    color: #DADADA;
    background-color: #DADADA;
    margin-block-end: 35px;
}

.addButton.addButton {
    margin-block-end: 55px;
    font-size: 13px;
    font-weight: 600 !important;
    padding: 15px 30px;
    border-width: 2px;
    border-radius: 7px;
    margin-inline-end: 30px;
}

.icon {
    margin-inline-start: 5px;
}

.table {
    display: grid;
    grid-template-rows: 45px;
    grid-auto-rows: 127px;
    border-radius: 7px;
    overflow: hidden;
    gap: 1px;
    background-color: #DADADA;
    border: 1px solid #DADADA;
}

.row {
    display: grid;
    position: relative;
    background-color: white;
    grid-template-columns: 150px 280px 260px 440px 220px auto;
    gap: 15px;
}

.titles {
    background-color: #F8FAFF;
}

.titles>h3 {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    margin-inline-start: 15px;
    margin-block-end: 0;
}

.logo {
    margin: 30px 15px;
    height: 67px;
    max-width: 100%;
    object-fit: contain;
}

.row>span,
.row>a {
    font-size: 13px;
    display: flex;
    margin-block-start: 10px;
    margin-inline-start: 15px;
}

.description {
    overflow-y: scroll;
    scrollbar-color: #81848D;
    scrollbar-width: thin;
    margin-block-end: 15px;
}

.description::-webkit-scrollbar {
    width: 5px;
}

.description::-webkit-scrollbar-thumb {
    background-color: #81848D;
    /* цвет плашки */
    border-radius: 10px;
    /* закругления плашки */
}

.buttons {
    position: absolute;
    right: 0;
    bottom: 15px;
    display: flex;
    gap: 25px;
    margin-inline-end: 15px;
}

.pageButtons {
    display: flex;
    margin-block-end: 30px;
}

.allEventsButton,
.editEventButton {
    color: #81848D;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    margin-inline-end: 20px;
    padding-block: 10px;
}

.editEventButton {
    display: none;
    padding-inline: 30px;
}

.activeWindow {
    display: block;
    border-bottom: 3px solid #81848D;
    color: black;
}

.addButton:hover .loadIcon {
    fill: white;
}