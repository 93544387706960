.form {
    width: 400px;
    height: auto;
    background: #fff;
    border-radius: 7px;
    padding: 15px;
    border: 1px solid #CFCFCF;
    border-radius: 10px;
}

.button {
    width: 140px;
    height: 40px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-block-end: 30px;
}

.input {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    color: #81848D;
    font-weight: 600;
}

.inputArea {
    margin-block-start: 5px;
    height: 40px;
    border: 1px solid #DADADA;
    border-radius: 3px;
    font-size: 13px;
    padding: 13px 10px;
}

.buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.form > h3 {
    margin-block-end: 20px;
    font-size: 20px;
}